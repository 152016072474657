<template>
  <div style="position:relative; height: calc(100vh - 270px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <h3>{{ $t('conversation.title') }}</h3>
          <div>
            <v-btn color="green" dark raised tile @click="getConversations">
              <v-icon size="18" class="mr-2">mdi-reload</v-icon>
              {{ $t('conversation.tags.reload') }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12">
              <strong  ref="conversationTop">
                {{ $t('conversation.employee') }}
              </strong>
              <span>
              {{ this.form.employee }}
            </span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  v-model="date.date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="computedDateFormatted"
                      label="Date"
                      hide-details
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date.date"
                    no-title
                    @input="date.date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menu"
                  v-model="date.time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="date.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date.time"
                      label="Zeit"
                      readonly
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="date.time_menu"
                    v-model="date.time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu.save(date.time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-select v-model="form.conversation_on_id"
                        :label="$t('conversation.form.tags') + ' *'"
                        :placeholder="$t('conversation.form.tags_placeholder')"
                        :items="conversation_ons"
                        item-value="id"
                        item-text="display_text"
                        outlined
                        hide-details
                        :append-icon="'mdi-chevron-down'"
                        :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols="12" class="d-flex align-center">
              <v-text-field
                  v-model="form.contact_person"
                  outlined
                  hide-details
                  :label="$t('conversation.form.contact_person')"
                  :placeholder="$t('conversation.form.contact_person')"
                  required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                  outlined
                  :label="$t('conversation.form.description') + ' *'"
                  v-model="form.description"
                  rows="3"
                  hide-details
                  :error-messages="errors.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                  style="width: 100%; padding: 28px;"
                  elevation="0"
                  color="green"
                  @click="save"
                  :loading="btn_save_loading"
                  :disabled="btn_save_disabled"
              >
                {{ $t('document.category.buttons.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="conversations.length === 0" cols="12" md="8" :style="`height: ${height}px; overflow-x: auto`">
          <h3 style="border: 1px dashed #cecece; border-radius: 6px; width: 100%; height: 100%;" class="d-flex align-center justify-center">
            {{ $t('conversation.not_histories') }}
          </h3>
        </v-col>

        <v-col v-else cols="12" md="8" class="d-flex" :style="`height: ${height}px; overflow-x: auto`">
          <div class="mx-conversation-items">
            <div class="mx-conversation-item" v-for="[title, conversation] in Object.entries(conversations)">
              <div class="mx-conversation-date">
                <span>{{ title }}</span>
              </div>
              <div class="mx-conversation-content">
                <div class="mx-conversation-content-item" v-for="item in conversation">
                  <div class="mx-conversation-content-left">
                    <v-icon class="mx-conversation-content-left__icon" size="20">mdi-clock-time-eight-outline</v-icon>
                    <span class="mx-conversation-content-left__text">{{ item.time }}</span>
                  </div>
                  <div class="mx-conversation-content-right" :style="`border-left-color: ${item.on.color}`">
                    <div class="mx-conversation-content-right__top">
                      <div class="mx-conversation-content-right__on" :style="`color: ${item.on.color}`">
                        {{ item.on.display_text }}
                      </div>
                      <div class="mx-conversation-content-right__block">
                        <span class="mx-conversation-content-right__block-b">{{ $t('conversation.employee') }}</span>
                        <span class="mx-conversation-content-right__block-t">{{ item.employee }}</span>
                      </div>
                      <div class="mx-conversation-content-right__block">
                        <span class="mx-conversation-content-right__block-b">{{ $t('conversation.contact_person') }} </span>
                        <span class="mx-conversation-content-right__block-t">{{ item.contact_person }}</span>
                      </div>
                    </div>
                    <div class="mx-conversation-content-right__text">
                      {{ item.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import axios from "axios";
import moment from "moment";


export default {
  name: "Conversation",
  props: {
    company_id: {
      required: true
    }
  },
  data() {
    return {
      open_tags_dialog: false,
      form: {
        company_id: "",
        employee: "",
        employee_id: "",
        contact_person: "",
        description: "",
        conversation_on_id: null,
        date: "",
      },

      date: {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date_menu: false,


        time: moment().format('HH:mm'),
        time_menu: false,
      },

      conversations:{},
      conversation_ons: [],
      height: 0,
      errors: {},
      preloader: true,
      btn_save_loading: false,
      btn_save_disabled: true
    }
  },
  watch: {
    form: {
      handler(val){
        this.valid()
      },
      deep: true
    },
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date.date)
    },
  },
  created() {
    this.getConversations()

    setTimeout( () => {
      const conversationTop = parseInt(this.$refs.conversationTop.getBoundingClientRect().top + 20)
      this.height = window.innerHeight - conversationTop
    }, 1)

    this.form.employee_id = this.$store.getters.user.id
    this.form.employee = this.$store.getters.user.first_name + ' ' + this.$store.getters.user.last_name
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    valid() {
      if (this.form.description !== '' && this.form.conversation_on_id !== null) {
        this.btn_save_disabled = false
      } else {
        this.btn_save_disabled = true
      }
    },
    async getConversations() {
      this.form.company_id = this.company_id
      await axios.get(`/companies/conversation-history/${this.company_id}`).then(response => {
        this.conversations = response.data.data.conversations
        this.conversation_ons = response.data.data.conversation_ons
        this.preloader = false
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            console.log('[ERROR 500]', error);
            setTimeout(this.getConversations, 30)
            break;
        }
      })
    },
    async save() {
      this.btn_save_loading = true
      this.form.date = this.date.date + ' ' + this.date.time
      await axios.post(`/companies/conversation-history`, this.form).then(response => {
        this.getConversations()
        this.btn_save_loading = false
        this.form.description = ''
        this.form.contact_person = ''
        this.form.conversation_on_id = null
      })
    }
  }
}
</script>

<style lang="scss">

.mx-conversation-items {
  width: 100%;
  .mx-conversation-item {
    display: flex;
    //align-items: center;
    margin-bottom: 30px;
    border: 1px dashed #cecece;
    padding: 8px 18px;
    border-radius: 6px;

    .mx-conversation-date {
      margin-right: 10px;
      font-weight: 700;
      font-size: 18px;
      //border-right: 2px solid #cecece;
      display: flex;
      align-items: center;
      padding-right: 20px;
      position: relative;

      span {
        position: sticky;
        top: 0;
      }
      //&:before {
      //  content: '';
      //  height: 40px;
      //  width: 2px;
      //  background: #fff;
      //  position: absolute;
      //  top: 0;
      //  right: -2px;
      //}
      //&:after {
      //  content: '';
      //  height: 40px;
      //  width: 2px;
      //  background: #fff;
      //  position: absolute;
      //  bottom: 0;
      //  right: -2px;
      //}
    }

    .mx-conversation-content {
      width: 100%;
      .mx-conversation-content-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        background: #F8F8F8;
        border-radius: 2px;
        padding: 10px 0;

        &:last-child {
          margin-bottom: 0;
        }

        .mx-conversation-content-left {
          padding: 0 18px;
          display: flex;
          position: relative;
          //&:before {
          //  content: '';
          //  width: 20px;
          //  height: 2px;
          //  position: absolute;
          //  top: 29px;
          //  left: 0;
          //  background: red;
          //}
          .mx-conversation-content-left__icon {
            margin-right: 10px;
            color: #5B5B5B;
          }

          .mx-conversation-content-left__text {
            font-weight: 700;
            font-size: 16px;
            color: #5B5B5B;
          }
        }

        .mx-conversation-content-right {
          border-left: 2px solid;
          padding: 5px 20px;

          &__top {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
          }

          &__text {
            font-size: 15px;
            line-height: 20px;
          }

          .mx-conversation-content-right__on {
            font-size: 12px;
            font-weight: 500;
            margin-top: 3px;
          }

          .mx-conversation-content-right__block {
            color: #5B5B5B;
            margin-left: 20px;

            &-b {
              font-size: 12px;
              font-weight: 500;
              margin-right: 5px;
            }

            &-t {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
</style>
